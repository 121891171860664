import Icon from "@mkt/ui/components/common/Icon";

export default function Twitter(props) {
  return (
    <Icon
      iconMarkup={'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g><path d="M15.5 31C7 31 0 24 0 15.5S7 0 15.5 0 31 7 31 15.5 24 31 15.5 31zm0-30C7.5 1 1 7.5 1 15.5S7.5 30 15.5 30 30 23.5 30 15.5 23.5 1 15.5 1z"/><path d="M9.8 13.3h2.3v8.2H9.8v-8.2zm1.1-1c-.9 0-1.4-.6-1.4-1.4 0-.8.6-1.4 1.4-1.4.9 0 1.4.6 1.4 1.4 0 .8-.5 1.4-1.4 1.4zm10.6 9.2h-2.7v-4.2c0-1.1-.4-1.9-1.3-1.9-.7 0-1.1.5-1.3 1-.1.2-.1.4-.1.7v4.4h-2.6v-8.2h2.6v1.3c.2-.6 1-1.4 2.3-1.4 1.7 0 3 1.2 3 3.7v4.6h.1z"/></g></svg>'}
			iconName="Twitter"
      {...props}
    />
  );
}
