import Icon from "@mkt/ui/components/common/Icon"

export default function Arrow(props) {
  return (
    <Icon
      iconMarkup={`<?xml version="1.0" encoding="UTF-8"?>
      <svg viewBox="0 0 13 9">
        <g id="iqcontas" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Serviços-01" transform="translate(-99.000000, -306.000000)" fill="#43CA4D" fill-rule="nonzero">
                <g id="btn01" transform="translate(24.000000, 217.000000)">
                    <path d="M83.828125,97.171875 C83.6718742,97.0156242 83.6718742,96.8593758 83.828125,96.703125 L86.4453125,93.8125 L75.3125,93.8125 C75.1041656,93.8125 75,93.7083344 75,93.5 C75,93.2916656 75.1041656,93.1875 75.3125,93.1875 L86.4453125,93.1875 L83.828125,90.296875 C83.6718742,90.1406242 83.6718742,89.9843758 83.828125,89.828125 C83.9843758,89.6718742 84.1406242,89.6718742 84.296875,89.828125 C86.354177,92.0937613 87.3958332,93.2395832 87.421875,93.265625 C87.4739586,93.3177086 87.5,93.3958328 87.5,93.5 C87.5,93.6041672 87.4739586,93.6822914 87.421875,93.734375 L84.296875,97.171875 C84.2447914,97.2239586 84.1666672,97.25 84.0625,97.25 C83.9583328,97.25 83.8802086,97.2239586 83.828125,97.171875 Z" id=""></path>
                </g>
            </g>
        </g>
      </svg>
      `}
      iconName="Arrow"
      {...props}
    />
  )
}
