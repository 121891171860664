import Condition from "@mkt/ui/components/common/Condition"
import * as IconsComponent from "@mkt/ui/icons/Azulis"
import Text from "@mkt/ui/components/Azulis/Text"
import SbEditable from "@mkt/ui/components/common/SbEditable"
import { render } from "storyblok-rich-text-react-renderer"

import * as S from "./styles"

const Benefits = ({content}) => {

  const blok = content?.product_benefits[0]
  const {disclaimer} = content
  
  return (
    <div className="card-benefits" css={S.benefits}>
      <SbEditable content={blok}>
        {blok?.body.map((childBlok) => {
          const { _uid } = childBlok
          const Icon = IconsComponent[childBlok.icon]
          return (
            <SbEditable content={childBlok} key={`benefits-details-${_uid}`}>
              <div className="card-benefits__item" css={S.benefitsItem}>
                <div className="card-benefits__label" css={S.benefitsLabel}>
                  <Condition condition={!Icon}>
                    <div className="icon icon--fake" css={S.contentIcon}></div>
                  </Condition>
                  {!!Icon ? <Icon /> : null}
                  <Text as="p" variant={["SemiBold", "Line24"]}>
                    {childBlok.title}
                  </Text>
                </div>
                <div className="card-benefits__value" css={S.benefitsValue}>
                  {render(childBlok.desc)}
                </div>
              </div>     
            </SbEditable>
          )
        })}
        <Condition condition={disclaimer}>
          <div css={S.disclaimer}>{render(disclaimer)}</div>
        </Condition>
      </SbEditable>
    </div>
  )
}

export default Benefits
