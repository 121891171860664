import { useEffect } from "react"

import useAnalytics from "@mkt/ui/hooks/useAnalytics"

// To Do: duplicated file apps/ccbr/src/pages/Card/components/Content/hook

const useCardContent = (content) => {
	const { form, product } = useAnalytics()
  
	const name = 'card-referral'
	const type = 'soft-lead'
	const version = 'v1'
	
	useEffect(() => {
		product.setProduct(content)
	  	form.setForm({ name, type, version, brand: content.issuer, id: `azulis-${name}`})
	}, [])
  
}

export default useCardContent