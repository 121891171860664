import { css } from "@emotion/react"
import { above } from "@mkt/ui/styles/Breakpoints"
import { colors } from "iq-blueberry"

export const benefits = css`
  ${above["l"]} {
    margin-right: 32px;
  }
`

export const benefitsItem = css`
  margin-bottom: 20px;
`

export const contentIcon = css`
  display: block;
  width: 32px;
  height: 32px;
`

export const benefitsLabel = css`
  display: flex;
  align-content: center;

  .icon + p {
    margin-left: 16px;
    display: flex;
    align-content: center;
  }
`

export const benefitsValue = css`
  margin-left: 48px;

  &,
  p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    margin-bottom: 16px;
  }

  ul {
    margin-bottom: 16px;
    padding-left: 16px;

    li {
      list-style: disc;
    }

    p {
      margin-bottom: 6px;
    }
  }
`
export const disclaimer = css`
  margin-bottom: 24px;
  line-height: 22px;
  list-style: none;
  font-size: 14px;
  color: ${colors["gray-60"]};
`