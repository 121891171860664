import Icon from "@mkt/ui/components/common/Icon"

export default function CardNotFound(props) {
  return (
    <Icon
      iconMarkup={`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84 64">
        <g stroke="#0504EB" stroke-width="3" fill="none" fill-rule="evenodd">
        <path
          d="M81.999 29.5v-22a5.348 5.348 0 00-1.556-3.876A5.51 5.51 0 0076.548 2H7.712C4.603 1.99 2.06 4.438 2 7.5v44c.08 3.054 2.611 5.491 5.712 5.5h29.636"
          stroke-linecap="round"
        ></path>
        <path d="M2 13h80M2 24h80M8 31h13"></path>
        <path
          d="M70.91 45.454c0 6.327-5.13 11.455-11.456 11.455C53.13 56.91 48 51.781 48 45.454 48 39.13 53.129 34 59.454 34c6.327 0 11.456 5.129 11.456 11.454z"
          stroke-linecap="square"
        ></path>
        <path stroke-linecap="round" d="M68.364 54.364L76 62"></path>
      </g>
        </svg>`}
      iconName="CardNotFound"
      {...props}
    />
  )
}
