import Icon from "@mkt/ui/components/common/Icon";

export default function OutlinePayByCard(props) {
  return (
    <Icon
      iconMarkup={'<svg viewBox="0 0 34 29" id="pay-by-card" xmlns="http://www.w3.org/2000/svg"><g stroke="#0504EB" fill="none" fill-rule="evenodd" stroke-linecap="square"><path d="M12.71 28.752v-4.391s2.105-1.114 2.105-3.723v-2.096M7.448 9.039L2.9 12.616c-.81.738-1.416 1.92-1.416 3.04v13.095"></path><path d="M4.06 17.716s5.188-5.05 6.515-6.373c1.328-1.321 2.843 1.913 1.582 3.778-.567.78-2.877 3.356-2.877 3.356 1.283 2.854-.648 5.186-.648 5.186"></path><path d="M7.603 14.026V2.74a1.26 1.26 0 011.263-1.257h22.386a1.26 1.26 0 011.263 1.257v13.814a1.26 1.26 0 01-1.263 1.257H9.872"></path><path d="M28.16 9.647h-2.61a.642.642 0 01-.643-.64V7.302c0-.353.288-.64.644-.64h2.608c.355 0 .644.287.644.64v1.703a.643.643 0 01-.644.641zM21.146 13.45h7.657"></path></g></svg>'}
			iconName="OutlinePayByCard"
      {...props}
    />
  );
}
