import Text from "@mkt/ui/components/Azulis/Text"

import Condition from "@mkt/ui/components/common/Condition"
import * as S from "./styles"

import Benefits from "../Benefits"
import useCardContent from "./hook"

const Content = ({ content, children }) => {
  const title = content?.product_benefits?.length
    ? content?.product_benefits[0].title
    : ""

  useCardContent(content)
  return (
    <div className="article-content">
      <div css={S.articleContentContainer}>
        <div css={S.articleContentWrap}>
          <div css={S.articleContentCard}>
            <Text as="h3" variant="HeadingThree">
              {title}
            </Text>

            <Condition
              condition={
                !!content?.product_benefits &&
                content?.product_benefits?.length > 0
              }
            >
              <Benefits content={content} />
            </Condition>
          </div>
          
          <div css={S.articleFormContainer} data-id-ui="form-flow">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Content
