import Text from "@mkt/ui/components/Azulis/Text"
import Img from "@mkt/ui/components/common/Img"
import { OutlineWallet, OutlinePayByCard } from "@mkt/ui/icons/Azulis"
import Condition from "@mkt/ui/components/common/Condition"
import * as S from "./styles"

const Hero = ({ content }) => {
  return (
    <Condition condition={!!content}>
      <div className="article-hero">
        <div css={S.heroContainer}>
          <figure css={S.heroImageContainer}>
            <Img
              src={content.image.filename}
              alt={content.title}
              css={S.heroImage}
              width="323px"
              height="205px"
              loading="eager"
            />
          </figure>
          <div css={S.heroTitle}>
            <Text as="h1" variant="HeadingFour">
              Cartão {content?.title}
            </Text>
          </div>
          <ul css={S.heroDetailList}>
            <li css={S.heroDetailListItem}>
              <OutlineWallet />
              <Text
                as="h2"
                variant={["TextSubTitleSecondaryLight", "CardHeroDetail", "SemiBold"]}
              >
                Renda mínima:
              </Text>
              <Text as="p" variant="CardHeroDetail">
                {content?.minimum_income}
              </Text>
            </li>
            <li css={S.heroDetailListItem}>
              <OutlinePayByCard />
              <Text
                as="h2"
                variant={["TextSubTitleSecondaryLight", "CardHeroDetail", "SemiBold"]}
              >
                Anuidade:
              </Text>
              <Text as="p" variant="CardHeroDetail">
                {content?.annuity}
              </Text>
            </li>
          </ul>
        </div>
      </div>
    </Condition>
  )
}

export default Hero
